import React, { useState } from "react"
import { graphql } from "gatsby"
import PortableText from "react-portable-text"

import FutureDriven from "../images/logos/future_driven_logo.png"
import Autos2050Logo from "../images/logos/Autos2050_Logo_KO.png"
import OvertakeLogo from "../images/logos/overtake_logo.png"
import GetConnected from "../images/logos/Get_Connected_Full_Color.png"
import InsightsNews from "../images/logos/Insights_and_News_Full_Color.png"
import ReadingMeter from "../images/logos/Reading_the_Meter_Full_Color.png"
import BlueButton from "../components/blueButton"

import Modal from "../components/modal"
import SponsorshipForm from "../components/sponsorshipForm"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BeFeaturedBanner from "../components/beFeaturedBanner"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { AnimatePresence, motion } from "framer-motion"
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

function SponsorshipPage({ data }) {
  const [modalOpen, setModalOpen] = useState(false)
  const site = (data || {}).site
  const sponsors = (data || {}).sponsors
  const pdf = (data || {}).pdf

  // NOTE — this is a terrible fix for an issue with Portable Text. See: https://github.com/sanity-io/gatsby-source-sanity/issues/67#issuecomment-762499150
  site.description?.map(i => {
    i.markDefs = []
    return i
  })

  const swiperBreakpoints = {
    768: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 4,
    },
    1920: {
      slidesPerView: 5,
    },
  }

  const swiperAutoplay = {
    delay: 2500,
    disableOnInteraction: false,
  }

  return (
    <Layout title="sponsorship">
      <Seo title="Sponsorship" />

      <AnimatePresence exitBeforeEnter>
        {modalOpen && (
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            key="Sponsorship Form Sponsorship Page"
          >
            <SponsorshipForm />
          </Modal>
        )}
      </AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section className="flex flex-col items-center justify-center w-full py-32 text-center bg-center bg-no-repeat bg-cover bg-sponsorship-page-header-colorized">
          <h1 className="mb-4 text-4xl italic font-bold text-white lg:text-6xl xl:text-7xl">
            SPONSORSHIP
          </h1>
          <p className="w-10/12 max-w-lg mx-auto font-bold text-white lg:text-lg">
            If you're looking for unprecedented exposure in the auto industry's
            premiere tech event, look no further.
          </p>
        </section>

        <section className="flex flex-col items-center justify-center w-full py-8 bg-center bg-no-repeat bg-cover bg-white-left-starburst lg:py-16">
          <div className="w-10/12 max-w-3xl mx-auto text-left">
            <PortableText
              content={site.description}
              className="space-y-4"
              serializers={{
                h3: props => (
                  <h3
                    className="mb-4 text-lg font-bold text-gray-600"
                    {...props}
                  />
                ),
                normal: props => (
                  <p className="mb-4 text-base text-gray-500" {...props} />
                ),
              }}
            />
          </div>
        </section>

        <section className="flex flex-col items-center justify-center w-full py-16 text-white bg-center bg-no-repeat bg-cover h-80 bg-blue-right-starburst lg:py-24 lg:h-auto">
          <div className="w-10/12 max-w-3xl mx-auto space-y-4 text-center">
            <h2 className="text-2xl lg:text-4xl">
              {site && site.featuredHeadline}
            </h2>
            <h3 className="text-xl lg:text-2xl">
              {site && site.featuredDescription}
            </h3>
          </div>
        </section>

        <section className="w-full py-16 space-y-8 text-center text-white bg-dark-200 lg:pb-16 lg:pt-24 lg:space-y-16">
          <h2 className="text-2xl font-bold lg:text-4xl">
            Sponsorship Overview
          </h2>
          <div className="container grid w-10/12 grid-cols-1 gap-8 mx-auto my-4 place-items-center lg:grid-cols-2 lg:grid-rows-2">
            <div className="grid w-full h-full p-12 bg-black place-items-center lg:row-span-2 lg:p-16">
              <img
                src={Autos2050Logo}
                alt="Autos2050 Logo"
                className="mx-auto mb-2"
              />
              <div className="mx-auto my-2">
                <PortableText
                  content={site.autos2050Sponsorship}
                  className="space-y-4"
                  serializers={{
                    h3: props => (
                      <h3
                        className="mx-auto mb-2 text-xl font-bold text-secondary-100 lg:text-2xl"
                        {...props}
                      />
                    ),
                    h4: props => (
                      <h4
                        className="mx-auto text-base font-bold lg:text-lg"
                        {...props}
                      />
                    ),
                    h5: props => (
                      <h5
                        className="mx-auto text-sm font-bold lg:text-base"
                        {...props}
                      />
                    ),
                    normal: props => (
                      <p
                        className="mx-auto text-xs italic lg:text-sm"
                        {...props}
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <div className="w-full h-full p-12 bg-black lg:p-16">
              <img
                src={FutureDriven}
                alt="Future Driven Logo"
                className="mx-auto"
              />
              <div className="mx-auto mt-8">
                <PortableText
                  content={site.futureDrivenSponsorship}
                  className="space-y-2"
                  serializers={{
                    h3: props => (
                      <h3
                        className="mx-auto mb-2 text-xl font-bold text-secondary-100 lg:text-2xl"
                        {...props}
                      />
                    ),
                    h4: props => (
                      <h4
                        className="mx-auto text-base font-bold lg:text-lg"
                        {...props}
                      />
                    ),
                    h5: props => (
                      <h5
                        className="mx-auto text-sm font-bold lg:text-base"
                        {...props}
                      />
                    ),
                    normal: props => (
                      <p
                        className="mx-auto text-xs italic lg:text-sm"
                        {...props}
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <div className="w-full h-full p-12 bg-black lg:p-16">
              <img src={OvertakeLogo} alt="Overtake Logo" className="mx-auto" />
              <div className="mx-auto mt-8">
                <PortableText
                  content={site.overtakeSponsorship}
                  className="space-y-2"
                  serializers={{
                    h3: props => (
                      <h3
                        className="mx-auto mb-2 text-xl font-bold text-secondary-100 lg:text-2xl"
                        {...props}
                      />
                    ),
                    h4: props => (
                      <h4
                        className="mx-auto text-base font-bold lg:text-lg"
                        {...props}
                      />
                    ),
                    h5: props => (
                      <h5
                        className="mx-auto text-sm font-bold lg:text-base"
                        {...props}
                      />
                    ),
                    normal: props => (
                      <p
                        className="mx-auto text-xs italic lg:text-sm"
                        {...props}
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <div className="w-full h-full p-12 space-y-8 bg-black lg:col-span-2 lg:p-16">
              <h3 className="text-xl italic font-bold lg:text-3xl">
                <span className="font-light">AUTO INNOVATORS</span> PUBLICATIONS
              </h3>
              <div className="flex flex-col items-center justify-between space-y-16 lg:flex-row lg:space-y-0">
                <div className="w-72">
                  <img
                    className="w-64 mx-auto"
                    src={GetConnected}
                    alt="Get Connected"
                  />
                  <a
                    href={site.getConnectedPreview}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="inline-block px-8 py-2 mt-8 text-sm font-bold text-white uppercase transition rounded-full bg-primary hover:bg-opacity-75 focus:outline-none"
                  >
                    Preview
                  </a>
                </div>
                <div className="w-72">
                  <img
                    className="w-48 mx-auto"
                    src={InsightsNews}
                    alt="Insights and News"
                  />
                  <a
                    href={site.insightsNewsPreview}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="inline-block px-8 py-2 mt-8 text-sm font-bold text-white uppercase transition rounded-full bg-primary hover:bg-opacity-75 focus:outline-none"
                  >
                    Preview
                  </a>
                </div>
                <div className="w-72">
                  <img
                    className="w-48 mx-auto"
                    src={ReadingMeter}
                    alt="Reading the Meter"
                  />
                  <a
                    href={site.readingMeterPreview}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="inline-block px-8 py-2 mt-8 text-sm font-bold text-white uppercase transition rounded-full bg-primary hover:bg-opacity-75 focus:outline-none"
                  >
                    Preview
                  </a>
                </div>
              </div>
              <PortableText
                content={site.publicationSponsorship}
                className="space-y-2"
                serializers={{
                  h3: props => (
                    <h3
                      className="mx-auto mb-2 text-xl font-bold text-secondary-100 lg:text-2xl"
                      {...props}
                    />
                  ),
                  h4: props => (
                    <h4
                      className="mx-auto text-base font-bold lg:text-lg"
                      {...props}
                    />
                  ),
                  h5: props => (
                    <h5
                      className="mx-auto text-sm font-bold lg:text-base"
                      {...props}
                    />
                  ),
                  normal: props => (
                    <p
                      className="mx-auto text-xs italic lg:text-sm"
                      {...props}
                    />
                  ),
                }}
              />
            </div>
          </div>

          <div>
            <h3 className="w-8/12 mx-auto mb-8 text-sm italic font-bold md:text-base lg:w-auto lg:text-lg">
              Title sponsorships and customizable options available
            </h3>
            <a
              href={pdf.file.asset.url}
              target="_blank"
              rel="noreferrer noopener"
              className="block h-full"
            >
              <BlueButton>
                {new Date().getFullYear()} SPONSORSHIP GUIDE
              </BlueButton>
            </a>
          </div>
        </section>

        <section className="flex flex-col items-center justify-center w-full py-16 space-y-8 bg-center bg-no-repeat bg-cover h-80 bg-gray-right-starburst lg:py-24 lg:h-auto">
          <div className="w-10/12 max-w-2xl mx-auto space-y-4 text-center text-white">
            <h2 className="text-2xl font-bold lg:text-4xl">
              {site && site.readyHeadline}
            </h2>
            <p>{site && site.readyDescription}</p>
            <BlueButton onClick={() => setModalOpen(true)}>
              {site && site.readyButton}
            </BlueButton>
          </div>
        </section>

        <section className="relative w-full py-8 text-center bg-white lg:py-16">
          <h3 className="mb-4 text-2xl font-bold text-gray-600 lg:text-3xl">
            Our {new Date().getFullYear()} Sponsors
          </h3>
          <div className="relative w-full mx-auto overflow-x-hidden fade-edges lg:w-10/12">
            <Swiper
              slidesPerView={2}
              breakpoints={swiperBreakpoints}
              autoplay={swiperAutoplay}
              loop={true}
            >
              {sponsors &&
                sponsors.edges
                  .filter(sponsor =>
                    sponsor.node.year.some(
                      element =>
                        element.value === new Date().getFullYear().toString()
                    )
                  )
                  .map((sponsor, i) => (
                    <SwiperSlide key={i}>
                      <a
                        href={sponsor.node.company.url}
                        target="_blank"
                        rel="noreferrer"
                        className="relative block transition-all grayscale-effect"
                      >
                        <img
                          src={sponsor.node.company.logo.asset.url}
                          alt={sponsor.node.company.name}
                          className="object-contain object-center w-32 h-16 mx-auto"
                        />
                      </a>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </section>

        <section className="w-full py-16 text-center bg-light-100 lg:py-24">
          <h3 className="mb-8 text-2xl font-bold text-gray-600 text-secondary-300 lg:text-3xl">
            Thank You to Our Past Sponsors
          </h3>
          <div className="grid w-10/12 grid-cols-2 gap-8 mx-auto 2xl:grid-cols-5 place-items-center lg:grid-cols-3 lg:w-8/12 xl:grid-cols-4">
            {sponsors &&
              sponsors.edges
                .filter(sponsor =>
                  sponsor.node.year.some(
                    element =>
                      element.value !== new Date().getFullYear().toString()
                  )
                )
                .map(sponsor => (
                  <a
                    href={sponsor.node.company.url}
                    target="_blank"
                    rel="noreferrer"
                    className="transition transform hover:scale-105 active:scale-95"
                  >
                    <img
                      src={sponsor.node.company.logo.asset.url}
                      alt={sponsor.node.company.name}
                      className="object-contain object-center w-48 h-24 px-4 py-2 bg-white"
                    />
                  </a>
                ))}
          </div>
        </section>

        <BeFeaturedBanner />
      </motion.div>
    </Layout>
  )
}

export default SponsorshipPage

export const query = graphql`
  {
    site: sanitySponsorship {
      autos2050Sponsorship {
        style
        children {
          text
          marks
          _key
          _type
        }
        _type
        _rawChildren
        _key
        list
      }
      description {
        children {
          text
          marks
          _key
          _type
        }
        style
        list
        _type
        _rawChildren
        _key
      }
      futureDrivenSponsorship {
        style
        children {
          text
          _key
          _type
          marks
        }
        _key
        _rawChildren
        _type
        list
      }
      overtakeSponsorship {
        style
        children {
          text
          _key
          marks
          _type
        }
        _key
        _rawChildren
        _type
        list
      }
      readyButton
      readyDescription
      readyHeadline
      featuredHeadline
      featuredDescription
      insightsNewsPreview
      getConnectedPreview
      readingMeterPreview
      publicationSponsorship {
        _rawChildren
        _key
        children {
          text
          _type
          marks
          _key
        }
        style
        _type
        list
      }
    }
    sponsors: allSanitySponsor {
      edges {
        node {
          company {
            logo {
              asset {
                url
              }
            }
            url
            name
          }
          year {
            value
          }
          sponsorTypes {
            name
          }
        }
      }
    }
    pdf: sanitySponsorshipPdf {
      file {
        asset {
          url
        }
      }
    }
  }
`
